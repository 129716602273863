import React, { FC } from 'react';
import { graphql, navigate } from 'gatsby';
import { ContactPageFragment, ContactPageQuery, CTAContactFragment } from '../entities/operationResults';
import Meta from './atoms/Meta';
import withDefaultTransition from '../hocs/withDefaultTransition';
import loadable from '@loadable/component';

const ContactWizard = loadable(() => import('./organisms/ContactWizard'));

export const query = graphql`
    query ContactPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...ContactPageFragment
            }
            entries(site: $site, type: CTAContact, slug: "ontdek") {
                ...CTAContactFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment ContactPageFragment on Craft_Contact {
        title
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    data: ContactPageQuery;
}

const Contact: FC<Props> = ({ data }) => {
    if (!data?.craft?.entries?.[0] && !data?.craft?.entry) {
        return null;
    }

    const entry: CTAContactFragment = (data?.craft?.entries?.[0]! || data?.craft?.entry) as CTAContactFragment;
    const meta = (data.craft.entry as ContactPageFragment).seo?.[0];

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : entry.title}
                description={meta && meta.seoDescription ? meta.seoDescription : null}
                image={meta && meta.seoImage ? meta.seoImage : null}
            />

            <ContactWizard showPopover data={entry} setShowPopover={setShowPopover} variant="light" withDelay />
        </>
    );
};

const setShowPopover = (value: boolean) => {
    if (!value) {
        navigate('/');
    }
};

export default withDefaultTransition(Contact);
